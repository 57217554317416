const ReverseRouter = {
  default: () => "/",
  projects: {
    project: (slug: string) => `/project/${slug}`,
  },
  work: {
    default: () => "/work",
    work: (slug: string) => `/work/${slug}`,
  },
  about: {
    default: () => "/about",
  },
  post: {
    default: () => "/writing",
    post: (slug: string) => {
      return `/${slug}`
    },
    category: (slug: string, gotoPageNum: number = 1) => {
      return gotoPageNum === 1
        ? `/category/${slug}`
        : `/category/${slug}/${gotoPageNum}`
    },
    tag: (slug: string) => {
      return `/tag/${slug}`
    },
  },
  reading: {
    default: (gotoPageNum: number = 1) => {
      return gotoPageNum === 1 ? `/reading` : `/reading/${gotoPageNum}`
    },
  },
  archive: {
    date: (blog: string, gotoPageNum: number = 1) => {
      return gotoPageNum === 1
        ? `/${blog}/archive`
        : `/${blog}/archive/${gotoPageNum}`
    },
    category: (blog: string, category: string, gotoPageNum: number = 1) => {
      return gotoPageNum === 1
        ? `/${blog}/archive/category/${category}`
        : `/${blog}/archive/category/${category}/${gotoPageNum}`
    },
  },
  contact: {
    default: () => "/contact",
  },
  privacy: () => "/privacy",
}

export const rr = ReverseRouter
