import React from "react"
import ConstrainWidth from "./ConstrainWidth"
import Layout from "./Layout"
import * as styles from "./PageLayout.module.scss"

interface Props {
  title?: string
  uri: string
}

const PageLayout: React.FC<Props> = ({ title, uri, children }) => {
  return (
    <Layout title={title} uri={uri}>
      <ConstrainWidth>
        <div className={styles.PageLayout}>{children}</div>
      </ConstrainWidth>
    </Layout>
  )
}

export default PageLayout
