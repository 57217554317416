import React from "react"
import PageLayout from "../components/PageLayout"
import { graphql } from "gatsby"
import PostContent from "../components/PostContent"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`

const Page = ({ data, uri }) => {
  const { html, frontmatter } = data.markdownRemark
  return (
    <PageLayout title={frontmatter.title} uri={uri}>
      <PostContent body={html} />
    </PageLayout>
  )
}

export default Page
