import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ to, isBlank = false, children, ...rest }) => {
  return isBlank ? (
    <a href={to} target="_blank" rel="noopener" className="Link" {...rest}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={to} className="Link" {...rest}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  isBlank: PropTypes.bool,
}

export default Link
