import React, { ReactNode } from "react"
import * as styles from "./ConstrainWidth.module.scss"

interface Props {
  children: ReactNode
}

const ConstrainWidth: React.FC<Props> = ({ children }) => {
  return <div className={styles.div}>{children}</div>
}

export default ConstrainWidth
