import React from "react"
import * as styles from "./PostContent.module.scss"

interface PostContentProps {
  body: string
}

const PostContent: React.FC<PostContentProps> = ({ body }) => {
  return (
    <div
      className={styles.postContent}
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    ></div>
  )
}
export default PostContent
