import React, { ReactNode } from "react"
import Root from "./Root"
import SEO from "./SEO"
import Link from "./Link"
import { rr } from "../lib/reverse-router"
import ConstrainWidth from "./ConstrainWidth"
import * as styles from "./Layout.module.scss"

interface Props {
  title?: string
  uri: string
  description?: string
  children: ReactNode
}

const Layout: React.FC<Props> = ({ title, uri, description, children }) => {
  return (
    <Root>
      <SEO
        title={title}
        defaultTitle="Satoshi Murata"
        uri={uri}
        description={description}
      />
      <header className={styles.header}>
        <ConstrainWidth>
          <div className={styles.site}>
            <Link to={rr.post.default()}>Satoshi Murata</Link>
          </div>
        </ConstrainWidth>
      </header>
      <main className={styles.main}>{children}</main>
    </Root>
  )
}

export default Layout
